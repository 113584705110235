/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-cycle */
import {FilterModel, FilterType, IFilterModel, IQueryParamsFilter, ISorting} from '../types/galleryTypes';
import {PriceFilterModel} from '../models/PriceFilterModel';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {safeDecode} from '../viewerScript/utils';

const enum QueryParamActiveOptionStrategy {
  SingleCollection,
  MultiValue,
  Price,
}

export enum DefaultQueryParamKeys {
  Sort = 'sort',
  Page = 'page',
}

export const DefaultFilterId = 'default';

interface IQueryParamsObject {
  paramTitle: string;
  paramValue: string;
  isShown: boolean;
}
export class GalleryQueryParamsService {
  private readonly currentUrlSearchParams: IQueryParamsObject[];

  constructor(
    private readonly siteStore: SiteStore,
    private readonly isMobile: boolean,
    private readonly isCategoryPage
  ) {
    this.currentUrlSearchParams = [];
  }

  private readonly setAndUpdateQueryParams = (queryParam: IQueryParamsObject, forceUpdateFromUrl: boolean) => {
    this.setAndUpdateQueryParamsState(queryParam);
    if (!this.isMobile || forceUpdateFromUrl) {
      this.setAndUpdateQueryParamsUrl(queryParam);
    }
  };

  private readonly getDecodedSearchParams = (searchParams: {
    [paramKey: string]: string;
  }): {[paramKey: string]: string} => {
    return Object.keys(searchParams).reduce((res, key) => {
      const decodedKey = safeDecode(key);
      const decodedValue = safeDecode(searchParams[key]);
      const isParamValid = decodedKey && decodedValue;

      return isParamValid
        ? {
            ...res,
            [decodedKey]: decodedValue,
          }
        : res;
    }, {});
  };

  private readonly removeQueryParamState = (paramTitle: string): void => {
    const index = this.currentUrlSearchParams.findIndex((param) => param.paramTitle === paramTitle);
    if (index !== -1) {
      this.currentUrlSearchParams[index].isShown = false;
    }
  };

  private readonly removeQueryParam = (paramTitle, forceRemoveFromUrl = false) => {
    this.removeQueryParamState(paramTitle);
    if (!this.isMobile || forceRemoveFromUrl) {
      this.removeQueryParamUrl(paramTitle);
    }
  };

  private readonly removeQueryParamUrl = (paramTitle: string): void => {
    this.siteStore.location.queryParams.remove([paramTitle]);
  };

  private readonly setAndUpdateQueryParamsUrl = ({paramTitle, paramValue, isShown}: IQueryParamsObject) => {
    /* istanbul ignore else: no else needed */
    if (paramValue !== undefined) {
      if (isShown) {
        this.siteStore.location.queryParams.add({[paramTitle]: paramValue});
      } else {
        this.siteStore.location.queryParams.remove([paramTitle]);
      }
    }
  };

  protected readonly setAndUpdateQueryParamsState = ({paramTitle, paramValue, isShown}: IQueryParamsObject) => {
    let index = -1;
    this.currentUrlSearchParams.forEach((param, i) => {
      /* istanbul ignore next: todo: test */
      if (param.paramTitle === paramTitle) {
        index = i;
      }
    });

    if (index === -1) {
      this.currentUrlSearchParams.push({paramTitle, paramValue, isShown});
    } else {
      this.currentUrlSearchParams[index] = {paramTitle, paramValue, isShown};
    }
  };

  public readonly updateFiltersQueryParams = ({
    filterModel,
    mainCollectionId,
    forceUpdateFromUrl,
  }: {
    filterModel: IFilterModel;
    mainCollectionId: string;
    forceUpdateFromUrl: boolean;
  }) => {
    const {title, value} = this.getActiveFilterOptionByFilters(filterModel, mainCollectionId);

    this.setAndUpdateQueryParams({paramTitle: title, paramValue: value, isShown: !!value}, forceUpdateFromUrl);
  };

  public getActiveFilterOptionByFilters(
    filterModel: IFilterModel,
    mainCollectionId: string
  ): {title: string; value: string} {
    let activeFilterOption: string = '';
    const delimiter = ',';

    const filterTypeToActiveOptionStrategy = {
      [FilterType.COLLECTION]: this.isCategoryPage
        ? QueryParamActiveOptionStrategy.MultiValue
        : QueryParamActiveOptionStrategy.SingleCollection,
      [FilterType.LIST_OPTION]: QueryParamActiveOptionStrategy.MultiValue,
      [FilterType.CUSTOM_COLLECTION]: QueryParamActiveOptionStrategy.MultiValue,
      [FilterType.COLOR_OPTION]: QueryParamActiveOptionStrategy.MultiValue,
      [FilterType.PRICE]: QueryParamActiveOptionStrategy.Price,
    };

    switch (filterTypeToActiveOptionStrategy[filterModel.filterType]) {
      case QueryParamActiveOptionStrategy.SingleCollection: {
        if (filterModel.activeOptions === mainCollectionId) {
          activeFilterOption = `All`;
        } else {
          const filterOption = filterModel.options.find((option) => option.key === filterModel.activeOptions);
          activeFilterOption = filterOption.value;
        }
        break;
      }
      case QueryParamActiveOptionStrategy.MultiValue: {
        const filterOptions = filterModel.options.filter(
          (option) => filterModel.activeOptions.indexOf(option.key) > -1
        );

        if (filterOptions.length === 1) {
          activeFilterOption = filterOptions[0].value;
        } else if (filterOptions.length === 0) {
          this.removeQueryParam(filterModel.title);
        } else {
          activeFilterOption = filterOptions.map((filter) => filter.value).join(delimiter);
        }
        break;
      }
      case QueryParamActiveOptionStrategy.Price: {
        activeFilterOption = `${(filterModel.activeOptions as PriceFilterModel).minPrice}-${
          (filterModel.activeOptions as PriceFilterModel).maxPrice
        }`;
        break;
      }
    }

    return {title: filterModel.title, value: activeFilterOption};
  }

  public applyQueryParamsStateOnUrl = () => {
    const filtersToShow = {};
    const filtersToRemove = [];
    this.currentUrlSearchParams.forEach((queryParam) => {
      if (queryParam.isShown && queryParam.paramValue !== undefined) {
        filtersToShow[queryParam.paramTitle] = queryParam.paramValue;
      } else {
        filtersToRemove.push(queryParam.paramTitle);
      }
    });

    this.siteStore.location.queryParams.remove(filtersToRemove);
    this.siteStore.location.queryParams.add(filtersToShow);
  };

  public readonly getFiltersQueryParams = (filterModels: FilterModel[]): IQueryParamsFilter[] => {
    const decodedSearchParams = this.getDecodedSearchParams(this.siteStore.location.query);
    const activeFilters = [];

    const delimiter = ',';

    filterModels.forEach((filter) => {
      if (decodedSearchParams[filter.title]) {
        const paramsFilter = decodedSearchParams[filter.title];
        switch (filter.filterType) {
          case FilterType.LIST_OPTION:
          case FilterType.CUSTOM_COLLECTION:
          case FilterType.COLOR_OPTION:
          case FilterType.COLLECTION: {
            activeFilters.push({
              key: filter.title,
              value: paramsFilter,
              filterId: filter.filterId,
            });

            break;
          }
          case FilterType.PRICE: {
            const priceValue = paramsFilter.split('-');
            activeFilters.push({
              key: filter.title,
              value: `${priceValue[0]}${delimiter}${priceValue[1]}`,
              filterId: filter.filterId,
            });
          }
        }
        this.setAndUpdateQueryParamsState({paramTitle: filter.title, paramValue: paramsFilter, isShown: true});
      }
    });

    return activeFilters.length > 0 ? activeFilters : null;
  };

  public readonly getQueryParam = (paramTitle: string): string => {
    const queryParams = this.siteStore.location.query;
    this.setAndUpdateQueryParamsState({paramTitle, paramValue: queryParams[paramTitle], isShown: true});
    return queryParams[paramTitle];
  };

  public readonly clearAllFiltersQueryParams = (paramTitles: string[]) => {
    paramTitles.forEach((title) => {
      this.removeQueryParam(title);
    });
  };

  public readonly updatePageQueryParam = (page?: number): void => {
    if (page) {
      this.setAndUpdateQueryParams(
        {
          paramTitle: DefaultQueryParamKeys.Page,
          paramValue: page.toString(),
          isShown: true,
        },
        true
      );
    } else {
      this.removeQueryParam(DefaultQueryParamKeys.Page, true);
    }
  };

  public readonly getUrl = (): string => {
    const prefix = this.isCategoryPage ? (this.siteStore.location as any).prefix : undefined;
    return [this.siteStore.location.baseUrl, prefix, ...this.siteStore.location.path].join('/');
  };

  public readonly getUrlWithCustomPageParamForSeo = (page: number): string => {
    let url = this.getUrl();

    if (page > 1) {
      url += `?page=${page}`;
    }

    return url;
  };

  public readonly updateSortQueryParams = (selectedSort: ISorting, forceUpdateFromUrl: boolean) => {
    if (selectedSort.id === DefaultFilterId) {
      this.removeQueryParam(DefaultQueryParamKeys.Sort, forceUpdateFromUrl);
    } else {
      this.setAndUpdateQueryParams(
        {
          paramTitle: DefaultQueryParamKeys.Sort,
          paramValue: selectedSort.id,
          isShown: true,
        },
        forceUpdateFromUrl
      );
    }
  };
}
